// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-data-policy-html-js": () => import("./../../../src/pages/data-policy.html.js" /* webpackChunkName: "component---src-pages-data-policy-html-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-html-js": () => import("./../../../src/pages/privacy-policy.html.js" /* webpackChunkName: "component---src-pages-privacy-policy-html-js" */),
  "component---src-pages-terms-and-conditions-html-js": () => import("./../../../src/pages/terms-and-conditions.html.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-html-js" */),
  "component---src-pages-versions-desktop-js": () => import("./../../../src/pages/versions/desktop.js" /* webpackChunkName: "component---src-pages-versions-desktop-js" */),
  "component---src-pages-versions-mobile-js": () => import("./../../../src/pages/versions/mobile.js" /* webpackChunkName: "component---src-pages-versions-mobile-js" */),
  "component---src-pages-versions-web-js": () => import("./../../../src/pages/versions/web.js" /* webpackChunkName: "component---src-pages-versions-web-js" */)
}

